<template>
  <div>
    <van-popup
      v-model="showPopup"
      round
      position="bottom"
      class="popup"
      @click-overlay="setShow"
      :close-on-click-overlay="false"
    >
      <div class="goods-info">
        <div class="goods-img-box">
          <img v-if="show_img != ''" :src="show_img" />
        </div>
        <div class="price-info">
          <div class="goods-title">
            {{ goods_info.good_name }}
          </div>
          <div class="price">
            <div class="present-price" v-if="goodsClass.goodsType !== 3">
              <span>￥</span>
              <span class="integer">{{ toInteger(show_price) || 0 }}</span>
              <span>{{ twoFloating(show_price) || 0 }}</span>
            </div>
            <div class="integer" v-else>
              <span>{{ show_price }}公益豆</span>
            </div>
            <div class="original-price" v-if="goodsClass.goodsType !== 3">
              <span>￥</span>
              <span>{{ toInteger(show_pricing) || 0 }}</span>
              <span>{{ twoFloating(show_pricing) || 0 }}</span>
            </div>
          </div>
          <!-- <div class="order-num" v-show="select_spec.id * 1 > 0">
            库存数量：{{ select_spec.spec_stock }}
          </div> -->
        </div>
      </div>
      <div class="goods-show">
        <div class="goods-size" v-for="(spec, i_key) in item_list" :key="i_key">
          <div class="title">{{ spec.spec_name }}</div>
          <!-- 规格选择 -->
          <ul class="sku-box">
            <li
              class="size-sku"
              v-for="(item, index) in spec.items"
              :key="index"
              :class="{ 'back-color': item.is_select * 1 == 1 }"
              @click="chooseColor(i_key, index)"
            >
              {{ item.item }}
            </li>
          </ul>
        </div>
        <div class="number-of">
          <div>
            <span>购买数量</span>
            <span class="order-num">
              (库存数量{{ select_spec.spec_stock }})</span
            >
          </div>
          <van-stepper
            v-if="goodsClass.goodsType === 5"
            v-model="pay_num"
            :min="1"
            :max="pay_num_max"
            button-size="24"
          />
          <van-stepper
            v-else-if="goodsClass.goodsType !== 4"
            v-model="pay_num"
            :max="pay_num_max"
            :min="1"
            button-size="24"
          />
          <van-stepper
            v-else
            v-model="pay_num"
            :min="1"
            :max="1"
            button-size="24"
          />
        </div>
      </div>
      <div class="button-max-box">
        <div v-if="goodsClass.goodsType !== 1">
          <van-button
            v-if="goodsClass.goodsType === 3"
            type="primary"
            block
            round
            color="linear-gradient(to right, #F25757, #ED301D)"
            class="button"
            @click="skuButton"
          >
            立即兑换
          </van-button>
          <van-button
            v-else-if="select_spec.spec_stock > 0"
            type="primary"
            block
            round
            color="linear-gradient(to right, #F25757, #ED301D)"
            class="button"
            @click="skuButton"
          >
            {{ statusCode === 1 ? "加入购物车" : "立即购买" }}
          </van-button>
          <van-button
            v-else
            type="primary"
            block
            round
            color="#aaa"
            class="button"
          >
            已售罄
          </van-button>
        </div>
        <div class="button-double" v-else>
          <img src="~@/assets/order/join-cart.png" @click="addShopping" />
          <img src="~@/assets/order/buy.png" @click="submitOrder" />
        </div>
      </div>
      <van-icon
        name="cross"
        class="icon"
        color="#707070"
        size="20"
        @click="setShow"
      />
    </van-popup>
  </div>
</template>

<script>
import { getGoodsSpec } from "@/api/goods.js";
import { getSeckillSpec } from "@/api/seckill";
import { getBenefitSpec } from "@/api/benefit.js";
import { addShopping } from "@/api/shopping.js";
import { getNewUserGoodsSpec } from "@/api/NewUser.js";
import { Toast } from "vant";
import tools from "@/common/js/tools";

export default {
  name: "sku",
  props: {
    goodsClass: {
      default: {},
    },
    showPopup: {
      default: false,
    },
    statusCode: {
      default: "",
    },
    activity_id: {
      default: "",
    },
  },
  data() {
    return {
      stepperMinNum: 1,
      stepperMaxNum: 1,
      astrict_info: { is_astrict: 0, astrict_num: 0 },
      goods_info: { is_astrict: 0, astrict_num: 0 },
      item_list: [],
      rows: {},
      max_price: 0,
      mix_price: 0,
      max_pricing: 0,
      mix_pricing: 0,
      show_price: 0,
      show_pricing: 0,
      show_img: "",
      active: 0,
      select_spec: {
        id: 0,
        stock: 0,
      },
      select_ids: [],
      pay_num: 1,
      pay_num_max: 99999,
    };
  },
  mounted() {
    if (this.goodsClass.goodsType === 3) {
      this.getBenefitSpec();
    } else if (this.goodsClass.goodsType === 4) {
      this.getSeckillSpec();
    } else if (this.goodsClass.goodsType === 5) {
      this.getNewUserGoodsSpec();
    } else {
      this.getGoodsSpec();
    }
  },
  watch: {
    pay_num: function () {
      if (this.select_spec.id > 0) {
        if (this.pay_num > this.select_spec.spec_stock) {
          this.pay_num = 1;
        }
      }
      if (this.goodsClass.goodsType !== 5) {
        if (
          this.goods_info.is_astrict === 1 &&
          this.goods_info.astrict_num > 0
        ) {
          if (this.pay_num > this.goods_info.astrict_num) {
            Toast.fail("此商品最多购买" + this.goods_info.astrict_num + "件");
            this.pay_num_max = this.goods_info.astrict_num;
            this.pay_num = this.goods_info.astrict_num;
          }
        }
      } else {
        if (this.pay_num >= this.pay_num_max) {
          Toast.fail("此商品最多购买" + this.pay_num_max + "件");
        }
      }
    },
  },
  methods: {
    verification() {
      // 提交验证
      // console.log("-----------------执行验证");
      let index = -1;
      for (const k in this.item_list) {
        index = this.item_list[k].items.findIndex((optionVal, optionIndex) => {
          return optionVal.is_select == 1;
        });
        if (index <= -1) {
          Toast.fail(`请选择${this.item_list[k].spec_name}`);
          return false;
        }
      }
      return true;
    },
    async skuButton() {
      if (this.statusCode === 1) {
        this.addShopping();
      } else {
        this.submitOrder();
      }
    },
    setShow() {
      this.$emit("closeSku", false);
    },
    // setPayStock() {

    // },
    chooseColor(i_key, index) {
      this.item_list[i_key].items[index].is_select =
        this.item_list[i_key].items[index].is_select * 1 == 1 ? 0 : 1;
      // 清楚旧Key
      for (const key in this.item_list[i_key].items) {
        if (key != index) {
          this.item_list[i_key].items[key].is_select = 0;
          const old_id_key = this.select_ids.indexOf(
            this.item_list[i_key].items[key].item_id
          );
          if (old_id_key >= 0) this.select_ids.splice(old_id_key, 1);
        }
      }
      const id_key = this.select_ids.indexOf(
        this.item_list[i_key].items[index].item_id
      );
      if (this.item_list[i_key].items[index].is_select * 1 === 1) {
        // 执行添加操作
        if (id_key < 0) {
          this.select_ids[this.select_ids.length] =
            this.item_list[i_key].items[index].item_id;
        }
      } else {
        // 执行退出操作
        this.select_ids.splice(id_key, 1);
      }
      this.setSelectSpec();
    },
    setSelectSpec() {
      // 计算选择规格的相关信息
      this.select_ids.sort(sortNumber);
      const select_key = this.select_ids.join("_");
      let pay_num_max = 0;
      if (this.goods_info.is_astrict === 1 && this.goods_info.astrict_num > 0) {
        pay_num_max = this.goods_info.astrict_num;
      }
      if (this.rows[select_key] !== undefined) {
        this.select_spec = this.rows[select_key];
        this.show_price = this.select_spec.spec_price;
        this.show_pricing = this.select_spec.spec_pricing;
        this.show_img = this.select_spec.spec_img;
        this.stock = this.select_spec.spec_stock * 1;
        if (this.pay_num > this.select_spec.spec_stock) {
          this.pay_num = this.select_spec.spec_stock;
        }
        if (pay_num_max <= 0 || pay_num_max >= this.stock) {
          pay_num_max = this.stock;
        }
      } else {
        this.select_spec = { id: 0, stock: 0 };
        this.show_price = this.mix_price;
        this.show_pricing = this.max_pricing;
        this.show_img = this.goods_info.cover_img;
      }
      if (this.rows[select_key].is_quota === 1) {
        pay_num_max = this.rows[select_key].quota_num;
        this.goods_info.is_quota = 1;
      } else {
        pay_num_max = this.rows[select_key].spec_stock;
        this.goods_info.is_quota = 0;
      }
      this.pay_num_max = pay_num_max;
    },
    async getNewUserGoodsSpec() {
      const ret = await getNewUserGoodsSpec({
        newcomer_id: this.goodsClass.goodsId,
      });
      if (ret.code === 1) {
        if (ret.data.goods_info.is_quota === 1) {
          this.stepperMaxNum = ret.data.goods_info.quota_num;
        }
        this.goods_info = ret.data.goods_info;
        this.astrict_info = ret.data.astrict_info;
        this.item_list = ret.data.item_list;
        this.rows = ret.data.rows;
        this.max_price = ret.data.max_price;
        this.show_price = this.mix_price = ret.data.mix_price;
        this.show_pricing = this.max_pricing = ret.data.max_pricing;
        this.show_img = this.goods_info.cover_img;
        this.mix_pricing = ret.data.mix_pricing;
        this.item_list.forEach((value, v_key) => {
          value.items.forEach((item, i_key) => {
            if (item.is_select * 1 === 1) {
              this.select_ids[this.select_ids.length] = item.item_id;
            }
          });
        });
        // console.log(this.select_ids);
        this.setSelectSpec();
      }
    },
    async getBenefitSpec() {
      // 公益豆商品规格
      const res = await getBenefitSpec({
        benefit_id: this.goodsClass.goodsId,
      });
      if (res.code * 1 === 1) {
        this.goods_info = res.data.goods_info;
        this.item_list = res.data.item_list;
        this.rows = res.data.rows;
        this.max_price = res.data.max_price;
        this.show_price = this.mix_price = res.data.mix_price;
        this.show_pricing = this.max_pricing = res.data.max_pricing;
        this.show_img = this.goods_info.cover_img;
        this.mix_pricing = res.data.mix_pricing;
        this.item_list.forEach((value, v_key) => {
          value.items.forEach((item, i_key) => {
            if (item.is_select * 1 === 1) {
              this.select_ids[this.select_ids.length] = item.item_id;
            }
          });
        });
        // console.log(this.select_ids);
        this.setSelectSpec();
      }
    },
    integerNum(num) {
      // 获取价格整数部分
      num = this.twoNum(num) + "";
      const result = num.substring(0, num.indexOf("."));
      return result;
    },
    floorNum(num) {
      // 获取价格小数部分
      return tools.twoFloating(num);
      // num = this.twoNum(num) + "";
      // const result = num.substring(num.indexOf("."));
      // return result;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getSeckillSpec() {
      // 获取商品规格
      const ret = await getSeckillSpec({
        goods_id: this.goodsClass.goodsId,
        seckill_id: this.goodsClass.seckillId,
      });
      if (ret.code * 1 === 1) {
        this.goods_info = ret.data.goods_info;
        this.astrict_info = ret.data.astrict_info;
        this.item_list = ret.data.item_list;
        this.rows = ret.data.rows;
        this.max_price = ret.data.max_price;
        this.show_price = this.mix_price = ret.data.mix_price;
        this.show_pricing = this.max_pricing = ret.data.max_pricing;
        this.show_img = this.goods_info.cover_img;
        this.mix_pricing = ret.data.mix_pricing;
        this.item_list.forEach((value, v_key) => {
          value.items.forEach((item, i_key) => {
            if (item.is_select * 1 === 1) {
              this.select_ids[this.select_ids.length] = item.item_id;
            }
          });
        });
        // console.log(this.select_ids);
        this.setSelectSpec();
      }
    },
    async getGoodsSpec() {
      // 获取商品规格
      const ret = await getGoodsSpec({ goods_id: this.goodsClass.goodsId });
      if (ret.code * 1 === 1) {
        this.goods_info = ret.data.goods_info;
        this.astrict_info = ret.data.astrict_info;
        this.item_list = ret.data.item_list;
        this.rows = ret.data.rows;
        this.max_price = ret.data.max_price;
        this.show_price = this.mix_price = ret.data.mix_price;
        this.show_pricing = this.max_pricing = ret.data.max_pricing;
        this.show_img = this.goods_info.cover_img;
        this.mix_pricing = ret.data.mix_pricing;
        this.item_list.forEach((value, v_key) => {
          value.items.forEach((item, i_key) => {
            if (item.is_select * 1 === 1) {
              this.select_ids[this.select_ids.length] = item.item_id;
            }
          });
        });
        // console.log(this.select_ids);
        this.setSelectSpec();
      }
    },
    async submitOrder() {
      if (this.verification()) {
        const shopping_id = await this.addShopping();
        if (shopping_id <= 0) {
          // Toast.fail("购物车信息获取失败");
          return false;
        }
        //跳转至订单创建页面
        Toast.success("跳转至订单创建页面");
        if (this.goodsClass.goodsType === 2) {
          this.$router.push({
            name: "confirm-order",
            query: { shoppingIds: shopping_id, orderType: 2 },
          });
        } else if (this.goodsClass.goodsType === 3) {
          this.$router.push({
            path: "/commit-beans",
            query: { shoppingIds: shopping_id, orderType: 3 },
          });
        } else if (this.goodsClass.goodsType === 5) {
          this.$router.replace({
            path: "/create-order",
            query: { shoppingIds: shopping_id, orderType: 5 },
          });
        } else {
          this.$router.push({
            name: "create-order",
            query: {
              shoppingIds: shopping_id,
              bool: true,
              orderType: this.goodsClass.goodsType,
              seckillId: this.goodsClass.seckillId,
            },
          });
        }
      }
    },
    async addShopping() {
      if (this.verification()) {
        if (this.select_spec.id <= 0) {
          Toast.fail("请选择商品规格");
          return false;
        }
        if (this.pay_num <= 0) {
          this.pay_num = 1;
          // Toast.fail("请选择购买数量");
          // return false;
        }
        if (
          this.select_spec.spec_stock * 1 <= 0 ||
          this.select_spec.spec_stock * 1 < this.pay_num * 1
        ) {
          Toast.fail("商品库存不足");
          return false;
        }

        let shopping_id = 0;
        // console.log("this.select_spec.id------", this.select_spec.id);
        // console.log("this.activity_id------", this.activity_id);
        // console.log("this.statusCode------", this.statusCode);
        // console.log("goodsType------", this.goodsClass.goodsType);
        // console.log("this.pay_num------", this.pay_num);
        const ret = await addShopping({
          spec_id: this.select_spec.id,
          activity_id: this.activity_id,
          add_type: this.statusCode,
          type: this.goodsClass.goodsType,
          number: this.pay_num,
        });
        if (ret.code * 1 === 1) {
          shopping_id = ret.data.shopping_id;
          // this.$emit("setShowSku");
          this.$emit("setCartNum");
          this.$emit("closeSku", false, this.statusCode, 1);
          Toast.success(ret.msg);
        } else {
          Toast.fail(ret.msg);
        }
        return shopping_id;
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = Math.floor(num * 100) / 100;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      // return price;
    },
  },
};

// 排序
function sortNumber(a, b) {
  return a - b;
}
</script>

<style lang="less" scoped>
.van-popup {
  box-sizing: border-box;
  padding: 12px 12px 5px 12px;
  height: 586px;
  overflow-y: visible;
}

.goods-title {
  flex-shrink: 0;
  font-size: 17px;
  font-weight: 500;
  width: 238px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.button-max-box {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 388px;
  bottom: 6px;
  height: 52px;
}
.van-button__text {
  font-size: 21px;
}
.van-button--block {
  height: 52px;
}
.goods-show {
  margin-top: 120px;
  height: 374px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.goods-info-box {
  position: relative;
  width: 100%;
  height: 100%;
}
// 商品信息
.goods-info {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  justify-content: start;
  .goods-img-box {
    flex-shrink: 0;
    width: 110px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 110px;
      height: 110px;
      display: block;
    }
  }
}

.price-info {
  padding: 0 10px;

  .price {
    display: flex;
    justify-content: start;
    align-items: flex-end;
    margin-top: 10px;

    .present-price {
      color: #ed301d;
      font-size: 16px;
    }
    .integer {
      color: #ed301d;
      font-size: 21px;
    }
    .original-price {
      margin-left: 10px;
      color: #aaaaaa;
      font-size: 14px;
      text-decoration: line-through;
    }
  }
}

// 尺码信息
.goods-size {
  width: 100%;
  margin-bottom: 13px;
  .title {
    font-size: 17px;
    margin-top: 18px;
  }

  .sku-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
    .size-sku {
      max-width: 100%;
      max-height: 28px;
      padding: 4px 19px;
      margin: 0 13px 8px 0;
      border-radius: 100px;
      background-color: #fff4f4;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .back-color {
      // background-color: transparent !important;
      background-color: #fff4f4;
      color: red !important;
      // border: 1px solid red !important;
    }
  }
}

.color-choose {
  padding: 10px 0;
  border-bottom: 1px solid #ededed;

  .goods-color-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .goods-color {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      margin-top: 10px;
      padding: 0 6px;

      img {
        width: 52px;
      }

      span {
        color: #6f6f6f;
      }
    }
  }
}

.number-of {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  font-size: 17px;
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
}

.button-double {
  position: fixed;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  img {
    height: 52px;
  }
}
.icon {
  position: absolute;
  top: 12px;
  right: 12px;
}
.order-num {
  color: #6f6f6f;
  font-size: 17px;
}
</style>
