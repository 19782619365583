import request from './ajax'
// 秒杀模块
const seckillUrl = '/api/seckill/'
// 获取秒杀商品列表
export const getSeckillList = (data) => request(seckillUrl + 'goods', 'post', { ...data })
// 获取秒杀分组信息
export const getGroupList = (data) => request(seckillUrl + 'group', 'post', { ...data })
// 获取秒杀分类信息
export const getGroupGoodsClass = (data) => request(seckillUrl + 'class', 'post', { ...data })
// 获取秒杀商品详情
export const getSeckillInfo = (data) => request(seckillUrl + 'goods_info', 'post', { ...data })
// 获取秒杀规格信息
export const getSeckillSpec = (data) => request(seckillUrl + 'spec', 'post', { ...data })
//秒杀提醒
export const setRemind = (data) => request(seckillUrl + 'remind', 'post', { ...data })
// 创建秒杀订单
export const createSeckillOrder = (data) => request(seckillUrl + 'create', 'post', { ...data })
// 获取下单会员数据
export const getSeckillUser = (data) => request(seckillUrl + 'user', 'post', { ...data })
export const getSeckillShareInfo = (data) => request(seckillUrl + 'share', 'post', { ...data })
