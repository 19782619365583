import request from './ajax'
// 新人专区
const newUserUrl = '/api/newcomer/'
// 商品列表
export const getNewUserGoods = (data) => request(newUserUrl + 'goods', 'post', { ...data })
// 商品分类列表
export const getGoodsDetail = (data) => request(newUserUrl + 'goods_info', 'post', { ...data })
// 创建订单
export const createNewUserOrder = (data) => request(newUserUrl + 'create', 'post', { ...data })
// 获取规格
export const getNewUserGoodsSpec = (data) => request(newUserUrl + 'spec', 'post', { ...data })
// 获取分享信息
export const getNewUserShareInfo = (data) => request(newUserUrl + 'share', 'post', { ...data })